import React from 'react';
import { Parallax, Background } from 'react-parallax';
import styled from 'styled-components';
import '../../components/sliderStyles.css';
import { MainSection } from './sections/Main';
import parallax from '../../../public/images/parallax.svg';
import { Grid } from '../../components/Grid';
import { SEARCH_MODAL_LINK, SECTION, MESSAGE_TITLE } from '../../constants';

const Header = React.lazy(() => import('../../components/Header'));
const Footer = React.lazy(() => import('../../components/Footer'));
const HelpSection = React.lazy(() => import('./sections/Help'));
const IdeaSection = React.lazy(() => import('./sections/Idea'));
const ProjectSection = React.lazy(() => import('./sections/Project'));
const AdvantageSection = React.lazy(() => import('./sections/Advantage'));
const ContactsSection = React.lazy(() => import('../../components/Contacts'));
const BillingSection = React.lazy(() => import('./sections/Billing'));

const ParallaxImage = styled(Background)`
  height: 789px;
  width: 1743px;
  background: url('${parallax}') no-repeat;
`;

function App(): JSX.Element {
  return (
    <Grid.Container>
      <Parallax strength={2500}>
        <ParallaxImage />
        <Header
          title='Попробовать бесплатно'
          text='упрощает подбор деталей'
          buttonLink={SEARCH_MODAL_LINK}
          id='gtm_block_0'
        />
        <Grid.Main>
          <Grid.FlexibleWrapper>
            <MainSection id='gtm_block_1' />
            <HelpSection id='gtm_block_2' />
            <AdvantageSection id='gtm_block_3' />
            <ProjectSection id='gtm_block_4' />
            <IdeaSection id='gtm_block_5' />
            <BillingSection id='gtm_block_6' />
            <ContactsSection
              section={SECTION.DETAIL_SEARCH}
              title={MESSAGE_TITLE.DETAIL_SEARCH}
              id='gtm_block_7'
            />
          </Grid.FlexibleWrapper>
        </Grid.Main>
      </Parallax>
      <Footer />
    </Grid.Container>
  );
}

export { App };
