import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Heading, Text } from '../../../UI';
import borderImage from '../../../../public/images/main-section/border.svg';
import infoImage from '../../../../public/images/main-section/info-pss.png';
import { ReactComponent as SearchIcon } from '../../../../public/icons/search.svg';
import { SEARCH_MODAL_LINK } from '../../../constants';

const MainInfo = styled.div(
  ({ theme: { mediaQueries } }) => css`
    display: flex;
    flex-direction: column;

    ${mediaQueries.s} {
      flex-direction: column-reverse;
      margin-bottom: 16px;
    }
  `,
);

const TextContainer = styled.div(
  ({ theme: { mediaQueries } }) => css`
    max-width: 564px;
    font-size: 22px;

    ${mediaQueries.s} {
      font-size: 22px;
      max-width: 400px;
    }

    ${mediaQueries.xs} {
      max-width: 332px;
      font-size: 14px;
    }
  `,
);

const StrongContainer = styled.div(
  ({ theme: { mediaQueries } }) => css`
    font-weight: 800;
    display: inline;

    ${mediaQueries.s} {
      font-weight: 400;
      display: inline;
    }
  `,
);

const ImageBlock = styled.div(
  ({ theme: { mediaQueries } }) => css`
    position: relative;
    margin-top: -26px;
    margin-bottom: 15px;
    text-align: center;

    ${mediaQueries.s} {
      margin-top: 0;
    }
  `,
);

const BadgeContainer = styled.div(
  ({ theme: { mediaQueries } }) => css`
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-100%, -40%);

    ${mediaQueries.s} {
      display: none;
    }
  `,
);

const Hint = styled.div(
  ({ theme: { colors, mediaQueries } }) => css`
    position: absolute;
    right: 0;
    width: 480px;
    padding: 28px;
    margin-top: 4px;
    background: ${colors.primary.main};
    color: ${colors.white};
    opacity: 0;
    box-shadow: 0px 14px 24px rgba(62, 67, 104, 0.05);
    border-radius: 8px;
    text-align: left;
    transition: 0.2s ease-in-out opacity;

    ${mediaQueries.m} {
      right: -210px;
    }
  `,
);

const Badge = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: ${colors.primary.main};
    box-shadow: 0px 4px 11px -10px rgba(77, 124, 254, 0.2),
      0px 16px 28px -6px rgba(77, 124, 254, 0.29);

    &:hover + ${Hint} {
      opacity: 1;
    }
  `,
);

const Search = styled.div(
  ({ theme: { colors, mediaQueries } }) => css`
    margin-bottom: 150px;
    padding: 16px 28px 28px;
    background: ${colors.highlight[1]};
    border-radius: 16px;
    text-align: center;

    ${mediaQueries.s} {
      padding: 16px;
      margin-bottom: 50px;
      border-radius: 0;
      display: block;
      margin-left: -16px;
      margin-right: -16px;
    }
  `,
);

const TextInput = styled.input(
  ({ theme: { colors, mediaQueries } }) => css`
    width: 100%;
    background: ${colors.white};
    color: ${colors.text.primary};
    padding: 0 120px 0 16px;
    height: 54px;
    line-height: 54px;
    box-shadow: 0px 14px 24px rgba(62, 67, 104, 0.05);
    border-radius: 8px;
    text-overflow: ellipsis;

    ${mediaQueries.s} {
      padding-right: 62px;
    }
  `,
);

const InputWrapper = styled.div(
  ({ theme: { mediaQueries } }) => css`
    position: relative;

    svg {
      display: none;

      ${mediaQueries.s} {
        display: block;
      }
    }

    & ${Button} {
      position: absolute;
      right: 4px;
      top: 4px;

      ${mediaQueries.s} {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 46px;
        height: 46px;
        font-size: 0px;
        color: transparent;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 20px;
      right: -176px;
      width: 172px;
      height: 306px;
      background: url('${borderImage}');

      ${mediaQueries.l} {
        display: none;
      }
    }
  `,
);

function MainSection({ id }: ISection) {
  return (
    <>
      <MainInfo id={id}>
        <div>
          <Heading mt={9} mb={['10px', '10px', 0]}>
            Забудьте про рутину
          </Heading>
          <TextContainer>
            <Text mt={[0, 0, 4]} mb={['8px', '8px', 28]} fontWeight={400}>
              Автоматизированный{' '}
              <StrongContainer>поиск по соединениям</StrongContainer> найдет все
              комплектующие и их поставщиков за несколько кликов
            </Text>
          </TextContainer>
        </div>
        <ImageBlock>
          <img src={infoImage} alt='' />
          <BadgeContainer>
            <Badge>
              <SearchIcon />
            </Badge>
            <Hint>
              Документация оцифрована и занесена в систему в виде соединений.
              Вместо часов разбора бумаг — один поисковой запрос
            </Hint>
          </BadgeContainer>
        </ImageBlock>
      </MainInfo>
      <a
        href={SEARCH_MODAL_LINK}
        target='_blank'
        rel='noreferrer'
        style={{ cursor: 'pointer', color: 'black' }}
      >
        <Search>
          <Heading as='h5' mb={16}>
            Найдите соединение в istock.info
          </Heading>
          <InputWrapper>
            <TextInput
              disabled
              type='text'
              placeholder='Болтовое соединение'
              style={{ cursor: 'pointer' }}
            />
            <Button
              as='a'
              href={SEARCH_MODAL_LINK}
              target='_blank'
              rel='noreferrer'
            >
              <SearchIcon /> Найти
            </Button>
          </InputWrapper>
        </Search>
      </a>
    </>
  );
}

export { MainSection };
